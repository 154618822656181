<template>
    <b-container fluid class="blockS2 blockS2-M">
        <b-container>
           <b-row>
               <b-col xl="5" lg="6" md="8" class="text-left">
                    <p class="block2-p1">advantages</p>
                    <h2 class="blockS2-h2">Achieve superior <span class="span-cvt">efficiency</span></h2>
                    <p class="block4-p mt-4">At Pennsylvania Logistics, we do our best to help you solve any problem related to freight transportation. 99.5% of customers are satisfied with our logistics solutions.</p>
               </b-col>
           </b-row>
            <b-row class="mt-5 pt-3">
                <b-col md="6" lg="4" class="mb-5">
                    <b-card class="blockS2-card">
                        <img src="../img/blockS2-card1.svg" class="blockS2-img cardanimblock7card" id="img1blockS2" alt="">
                        <h3 class="block4-h3">Freight delivery <br> is always on time</h3>
                        <p class="block4-p">99.5% of the freight has been <br> delivered on time.</p>
                    </b-card>
                </b-col>
                <b-col md="6" lg="4" class="mb-5">
                    <b-card class="blockS2-card blockS2-mg-m">
                        <img src="../img/blockS2-card2.svg" class="blockS2-img cardanimblock7card" id="img2blockS2" alt="">
                        <h3 class="block4-h3">6 years of successful and  impeccable work</h3>
                        <p class="block4-p">We’ve been successful in the market for 6 years and continue to grow.</p>
                    </b-card>
                </b-col>
                <b-col md="6" lg="4" class="mb-5">
                    <b-card class="blockS2-card blockS2-mg-m">
                        <img src="../img/blockS2-card3.svg" class="blockS2-img cardanimblock7card" id="img3blockS2" alt="">
                        <h3 class="block4-h3">More than 500 <br> vans in our fleet</h3>
                        <p class="block4-p">There is always an available <br> van for your freight.</p>
                    </b-card>
                </b-col>
                <b-col md="6" lg="4" class="mb-5">
                    <b-card class="blockS2-card" >
                        <img src="../img/blockS2-card4.svg" class="blockS2-img cardanimblock7card" id="img4blockS2" alt="">
                        <h3 class="block4-h3">Online app for easier <br> freight tracking</h3>
                        <p class="block4-p">Track your freight easily at any <br> time with our convenient app.</p>
                    </b-card>
                </b-col>
                <b-col md="6" lg="4" class="mb-5">
                    <b-card class="blockS2-card" >
                        <img src="../img/blockS2-card5.svg" class="blockS2-img cardanimblock7card" id="img5blockS2" alt="">
                        <h3 class="block4-h3">Customer support <br> available 24/7</h3>
                        <p class="block4-p">Need help or have a question? <br> Our support team works 24/7.</p>
                    </b-card>
                    <img src="../img/blockS2-img.svg" class="blockS2-img2" alt="">
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return{ 
            zip_from: null,
            zip_to: null,
        }
    },
    computed:mapGetters(['getZip']),
    methods:{
        ...mapMutations(['setZips']),
        submit(){
             this.setZips({zip_from:this.zip_from, zip_to:this.zip_to})
             this.$router.push('/get-quote')
        }
    }
}
</script>