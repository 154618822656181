<template>
  <div style="overflow:hidden">
    <TopNavBar></TopNavBar>
    <TopNavBar2 id="navbar2" class="tnb2none"></TopNavBar2>
    <blockS1></blockS1>
    <blockS12></blockS12>
    <blockS2></blockS2>
    <blockS3></blockS3>
    <block4></block4>
    <blockAB5></blockAB5>
    <blockS4></blockS4>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar from '../components/TopNavBar.vue'
import TopNavBar2 from '../components/TopNavBar2.vue'
import blockAB1 from '../components/blockAB1.vue'
import blockS1 from '../components/blockS1.vue'
import blockS2 from '../components/blockS2.vue'
import blockS3 from '../components/blockS3.vue'
import blockS4 from '../components/blockS4.vue'
import blockAB5 from '../components/blockAB5.vue'
import block4 from '../components/block4.vue'
import form1 from '../components/form.vue'
import Footer1 from '../components/footer.vue'
import blockS12 from '../components/blockS1-2.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/freight-transportation' }
      ]
    }
  },
  data() {
    
    return {
      metaData: {

        en: {
          Homepage: {
            title: 'FTL & LTL Freight Trucking and Shipping | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'keywords',
                'content': ' freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
              },
              {
                'name': 'description',
                'content': ' Get your freight delivered on time with no disruptions! More than 500 vans. Online app for easier freight tracking. 6 years of impeccable work.',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/freight-transportation',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'FTL & LTL Freight Trucking and Shipping | Pennsylvania Logistics',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Get your freight delivered on time with no disruptions! More than 500 vans. Online app for easier freight tracking. 6 years of impeccable work.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },
              {
                property: 'og:image',
                content: '../img/blockS1.webp',
                vmid: 'og:image'
              },


            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/freight-transportation' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar, blockAB1, form1, Footer1, blockAB5, block4, blockS1, blockS2, blockS3, blockS4, TopNavBar2,blockS12
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
  mounted() {
    this.anim()
  }
}
</script>
